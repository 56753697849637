import { useStore, actions } from "../../store";
import Falcon from "../SVGS/icons/Falcon";
import React, { useState } from "react";
const Button = ({ data }) => {
  const buttonNav = data;
  const [state, dispatch] = useStore();

  return (
    <button
      className="btn-main"
      onClick={() => {
        // dispatch(actions.setModal(true));
        window.open(
          "https://apps.shopify.com/synctrack?utm_source=synctrackio&utm_medium=menu&utm_campaign=website",
          "_blank"
        );
        GAEventsTracker("Button/click", `TRY FOR FREE`);
      }}
    >
      <Falcon />
      TRY FOR FREE
    </button>
  );
};

export default Button;
