import Link from "next/link";
import { v4 as uuidv4 } from "uuid";

const FooterSection = ({ title, subTitle }) => {
  return (
    <div className="col-md-2 col-lg-2 col-xl-2 mx-auto mb-4">
      <h2 className="mb-4 mt-4 footer-title">{title}</h2>
      {subTitle.map((item) => (
        <p key={uuidv4()}>
          <Link href={item.href}>
            <a
              onClick={() => {
                GAEventsTracker("footer/click", `${item.text}`);
              }}
              className="text-reset sub-text"
              target={item.target}
            >
              {item.text}
            </a>
          </Link>
        </p>
      ))}
    </div>
  );
};

export default FooterSection;
