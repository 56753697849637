import React from 'react';
import synctracklogo2 from '../../public/images/homepage/synctracklogo2.jpg';
import Image from 'next/future/image';
import logo from '../../public/images/home/logosynctrack.png';

const FooterLogo = () => {
  return (
    <div
      className="
          col-md-3 col-lg-4 col-xl-3
          mb-4
          d-flex
          align-items-center
          omg-logo-footer"
    >
      <Image height={40} src={logo} alt="synctrac add tracking paypal logo" />
    </div>
  );
};

export default FooterLogo;
