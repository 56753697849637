import NavLink from "./Header/NavLink";
import Button from "./Header/Button";
import Logo from "./Header/Logo";
import { useEffect, useState } from "react";
const Navbar = ({ data, btn }) => {
    const paddingNormal = 10;
    const paddingScroll = 12;
    const [paddingNav, setPaddingNav] = useState(10);
    useEffect(() => {
        const windowScroll = () => {
            if (window.pageYOffset > 0) {
                setPaddingNav(paddingScroll);
            } else {
                setPaddingNav(paddingNormal);
            }
        }
        window.addEventListener("scroll", windowScroll);
        return window.removeEventListener("scroll", windowScroll);
    }, []);
    return (
        <div
            className="omg-navbar"
            style={{
                boxShadow: "3px 3px 12px 0px rgb(0 0 0 / 6%)",
            }}
        >
            <nav className="container">
                <nav
                    className="navbar navbar-expand-lg brand omg-navbar"
                    style={{
                        padding: `${paddingNav}px 0`,
                        transition: "0.3s",
                    }}
                >
                    <div className="container-fluid">
                        <Logo />
                        <div
                            className="collapse navbar-collapse menu"
                            id="navbarSupportedContent"
                        >
                            <NavLink data={data} />
                            <Button data={btn} />
                        </div>
                    </div>
                </nav>
            </nav>
        </div>
    );
};

export default Navbar;
