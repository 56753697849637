import FooterLogo from "./Footer/FooterLogo";
import FooterSection from "./Footer/FooterSection";
import { v4 as uuidv4 } from "uuid";
const Footer = ({ data }) => {
  const dataMapFooter = data;
  return (
    <>
      <hr id="footer-line" className="container-fluid" />
      <div className="footer-wrap">
        <footer className="text-center text-lg-start text-muted footer">
          <section className="container">
            <div className="container text-center text-md-start">
              <div className="row mt-3">
                <FooterLogo />
                {Array.isArray(dataMapFooter.data) &&
                  dataMapFooter.data.map((item) => (
                    <FooterSection
                      title={item.title}
                      subTitle={item.subTitle}
                      key={uuidv4()}
                    />
                  ))}
                <h2 className="mb-2 footer-title">
                  {dataMapFooter.socialMedia.title}
                </h2>
                <div className="mb-2">{dataMapFooter.socialMedia.subTitle}</div>
                <div className="mb-4">
                  {dataMapFooter.socialMedia.icon.map((item) => (
                    <a
                      href={item.href}
                      target="_blank"
                      style={{ marginRight: "8px" }}
                    >
                      <img
                        src={`images/${item.logo}`}
                        alt="social media logo"
                      />
                    </a>
                  ))}
                </div>
              </div>
            </div>
          </section>

          <div
            className="text-center p-4"
            style={{ backgroundColor: "rgba(0, 0, 0, 0.05)" }}
          >
            Copyright ©{" "}
            <a
              className="text-reset sub-text fw-bold"
              href="https://www.omegatheme.com/"
              target="_blank"
              rel="noopener"
            >
              Omegatheme.com
            </a>
            . All Rights Reserved
          </div>
        </footer>
      </div>
    </>
  );
};

export default Footer;
