import Layout from "@/components/Layout";
import { NextSeo } from "next-seo";
import ReactGA from "react-ga";
import common from "public/locales/en/common";
import { defaultKeyword, defaultLinkTags } from "../SEO/next-seo.config"
import layout from "public/locales/en/layout";
import { useGaEventTracker } from "../hooks";
import { useEffect } from "react";
export const withPages =
  (seo = {}, dataTranslate, eventTracker = "") =>
    (Component) => {
      const FuncComponent = ({ children, ...props }) => {
        useEffect(() => {
          if (window !== undefined) {
            ReactGA.initialize([
              {
                trackingId: process.env.gaKey,
                debug: false,
                gaOptions: { cookieDomain: "auto" },
              },
            ]);
            window.GAEventsTracker = useGaEventTracker(eventTracker);
          }
        }, []);

        const data = {};
        dataTranslate &&
          dataTranslate.forEach((item) => {
            data[item] = common[item];
          });
        return (
          <Layout data={layout}>
            <NextSeo
              {...seo}
              noindex={false}
              additionalMetaTags={defaultKeyword}
              additionalLinkTags={defaultLinkTags}
            />
            <Component {...props} data={data}>
              {children}
            </Component>
          </Layout>
        );
      };

      return FuncComponent;
    };
