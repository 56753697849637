import Link from "next/link";
import ArrowDown from "../SVGS/icons/ArrowDown";
import ReactGA from "react-ga";

const NavLink = ({ data }) => {
  const dataMapNavbar = data;
  return (
    <ul className="navbar-nav me-auto mb-2 mb-lg-0">
      {dataMapNavbar.map((item, index) => {
        return item.subTitle === undefined ? (
          <li className="nav-item" key={index}>
            <Link href={item.href}>
              <a
                onClick={() => {
                  GAEventsTracker("Banner/click", `${item.title}`);
                  ReactGA.event({
                    category: "Navbar",
                    action: "Click Navigation",
                    label: `${item.title}`,
                  });
                }}
                className="nav-link active body-sm"
                aria-current="page"
                target={item.target}
              >
                {item.title}
              </a>
            </Link>
          </li>
        ) : (
          <li key={index} className="nav-item dropdown">
            <a
              className="nav-link body-sm"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
              onClick={() => {
                GAEventsTracker("Banner/click", `${item.title}`);
              }}
            >
              {item.title} <ArrowDown />
            </a>
            <ul className="dropdown-menu">
              {item.subTitle.map((e, index) => (
                <li key={index}>
                  <Link href={e.href}>
                    <a
                      onClick={() => {
                        GAEventsTracker("Banner/click", `${e.sub}`);
                      }}
                      className="dropdown-item body-sm"
                      target={e.target}
                    >
                      {e.sub}
                    </a>
                  </Link>
                </li>
              ))}
            </ul>
          </li>
        );
      })}
    </ul>
  );
};

export default NavLink;
