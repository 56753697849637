import Footer from "./Footer";
import Navbar from "./Navbar";
import Script from "next/script";
import ModalRe from "./ModalRe";

const Layout = ({ children, data }) => {
  const { navbar, footer, buttonNav } = data;
  return (
    <>
      <Script type="text/javascript" src="/static/bootstrap.min.js" />
      <Script
        async
        src={`https://www.googletagmanager.com/gtag/js?id=${process.env.gaKey}`}
      />
      <Script
        dangerouslySetInnerHTML={{
          __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${process.env.gaKey}', {
              page_path: window.location.pathname,
            });
          `,
        }}
      />
      <Navbar data={navbar} btn={buttonNav} />
      <ModalRe />
      {children}
      <Footer data={footer} />
    </>
  );
};

export default Layout;
